export function processDataDevices(data) {
    const series = {}
    data.series.forEach(serie => {
        if (serie.first) {
            // Dev
            series[serie.name] = JSON.parse(serie.first[0])
        }
        if (serie.fields) {
            // Prod
            series[serie.name] = JSON.parse(serie.fields[0]['values'][0])
        }
    })

    const devices = {
        Torniquete: [
            {
                "hostname": "-Torniquete Entrada D-",
                "ip": "10.255.206.134",
                "status": "ok"
            },
            {
                "hostname": "-Torniquete Entrada I-",
                "ip": "10.255.206.133",
                "status": "ok"
            },
            {
                "hostname": "-Torniquete Salida D-",
                "ip": "10.255.206.136",
                "status": "ok"
            }, {
                "hostname": "-Torniquete Salida I-",
                "ip": "10.255.206.135",
                "status": "ok"
            }
        ],
        Equipo: [
            {
                "hostname": "-Caja 1-",
                "ip": "10.255.218.5",
                "status": "ok"
            },
            {
                "hostname": "-Caja 2-",
                "ip": "10.255.218.4",
                "status": "ok"
            },
            {
                "hostname": "-Caja 3-",
                "ip": "10.255.218.9",
                "status": "ok"
            },
            {
                "hostname": "-Caja 4-",
                "ip": "10.255.218.2",
                "status": "ok"
            }
        ],
        Api: [
            {
                "hostname": "api_skyweb",
                "ip": "api_skyweb",
                "status": "ok"
            },
            {
                "hostname": "Janus",
                "ip": "Janus",
                "status": "ok"
            },
            {
                "hostname": "SkyPres",
                "ip": "SkyPres",
                "status": "ok"
            },


        ]
    }
    if (series['statusEquipos']) {
        const statusEquipos = series['statusEquipos'];
        Object.keys(devices).forEach(tipo => {
            devices[tipo].forEach(dispositivo => {
                statusEquipos.forEach(status => {
                    status.custom_data.forEach(custom => {
                        if (custom.address === dispositivo.ip) {
                            dispositivo.status = status.criticity;
                        }
                    });
                });
            });
        });
    }

    function setStatus(valor, indice) {
        if (!isNaN(valor)) {
            if (valor < 5) {
                devices.Api[indice].status = "ok";
            } else if (valor <= 10) {
                devices.Api[indice].status = "warning";
            } else {
                devices.Api[indice].status = "critical";
            }
        }
    }

    if (series['statusApiPrincipal']) {
        setStatus(series['statusApiPrincipal'][0].values, 0);
    }
    if (series['statusApiJanus']) {
        setStatus(series['statusApiJanus'][0].values, 1);
    }
    if (series['statusApiSkyPres']) {
        setStatus(series['statusApiSkyPres'][0].values, 2);
    }

    return devices;
}