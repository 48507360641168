export function processData(data) {
  const series = {}
  data.series.forEach(serie => {
    if (serie.first) {
      // Dev
      series[serie.name] = JSON.parse(serie.first[0])
    }
    if (serie.fields) {
      // Prod
      series[serie.name] = JSON.parse(serie.fields[0]['values'][0])
    }
  })
  console.log(series);
  const timeLabels = ['0:00', '1:00', '2:00', '3:00', '4:00', '5:00', '6:00', '7:00', '8:00', '9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];

  function createDefaultMetrics() {
    return {
      tickets: {
        labels: timeLabels,
        hoy: [],
        ayer: [],
      },
      dinero: {
        labels: timeLabels,
        hoy: [],
        ayer: [],
      },
      promociones: {
        repeticiones: [],
        total_compra: [],
      },
      payMethod: {
        methodType: [],
        count: [],
      },
      country: {
        name: [],
        region: [],
        numberVisits: [],
        countryCode: []
      }
    };
  }

  const ventas = {
    Online: {
      TotalMes: 0,
      TotalTicketsMes: 0,
      apdex: 0,
      sesionesActivas: 0,
      sesionesAyer: 0,
      actionDuration: 0,
      totalSesiones: 0,
      ...createDefaultMetrics(),
    },
    Presencial: {
      TotalMes: 0,
      TotalTicketsMes: 0,
      apdex: 0,
      ...createDefaultMetrics(),
    },
  };

  //Banderas
  const flagsCodes = require('../img/flags_codes.json');
  const flagsCodesMap = new Map(flagsCodes.map(item => [item.dataCode.toUpperCase(), item.title]));

  //Venta Online
  if (series['ventasMesOnline']) {
    ventas.Online['TotalMes'] = series['ventasMesOnline'][0]['TotalCompra']
  }
  const xLabel = []
  if (series['ventasAyer']) {
    series['ventasAyer'].forEach(venta => {
      //xLabel.push(venta['bin(timestamp, 10m)'].substring(11, 16))
      ventas['Online']['dinero']['ayer'].push(venta['TotalCompra'])
    })
    //ventas['Online']['dinero']['labels'] = xLabel
    //ventas['Presencial']['dinero']['labels'] = xLabel
  }
  if (series['ventasHoy']) {
    ventas['Online']['dinero']['hoy'] = []
    series['ventasHoy'].forEach(venta => {
      ventas['Online']['dinero']['hoy'].push(venta['TotalCompra'])
    })
  }

  if (series['ticketsMesOnline']) {
    ventas.Online['TotalTicketsMes'] = series['ticketsMesOnline'][0]['cantidad_Ventas']
  }
  if (series['ticketsAyer']) {
    series['ticketsAyer'].forEach(venta => {
      ventas['Online']['tickets']['ayer'].push(venta['TicketsVendidos'])
    })
  }
  if (series['ticketsHoy']) {
    ventas['Online']['tickets']['hoy'] = []
    series['ticketsHoy'].forEach(venta => {
      ventas['Online']['tickets']['hoy'].push(venta['TicketsVendidos'])
    })
  }

  if (series['operacionesCantidad']) {
    series['operacionesCantidad'].forEach(operacion => {
      ventas['Online']['promociones']['repeticiones'].push(
        parseInt(operacion.count)
      )
      ventas['Online']['promociones']['total_compra'].push(
        operacion.total_compra
      )
    })
  }

  if (series['metodosCantidad']) {
    series['metodosCantidad'].forEach(metodo => {
      ventas['Online']['payMethod']['methodType'].push(metodo.MetodoPago)
      ventas['Online']['payMethod']['count'].push(parseInt(metodo.total))
    })
  }

  if (series['apdexOnline']) {
    if (series['apdexOnline']) {
      let apdexOnline = parseFloat(series['apdexOnline'][0].values);
      if (!isNaN(apdexOnline)) {
        ventas['Online']['apdex'] = parseFloat(apdexOnline.toFixed(2));
      }
    }

  }

  if (series['sessionActiveOnline']) {
    ventas['Online'].sesionesActivas = series['sessionActiveOnline'][0].values;

  }

  if (series['actionDurationOnline']) {
    let actionDuration = series['actionDurationOnline'][0].values / 1000;
    actionDuration = actionDuration.toFixed(2);
    ventas['Online'].actionDuration = actionDuration;
  }

  if (series['countryVisit']) {
    const countryCodeMap = new Map(flagsCodes.map(item => [item.title, item.dataCode]));
    series['countryVisit'].forEach(visit => {
      processVisits(visit, countryCodeMap);
    });
  }
  function processVisits(visit, flagsCodeMap) {
    const country = visit.dimensionMap.country;
    const totalVisits = visit.values.filter(value => value !== null);
    const countryCode = flagsCodeMap.get(country) || 'unknown';
    //Push arreglo
    ventas.Online.country.name.push(country);
    ventas.Online.country.numberVisits.push(totalVisits);
    ventas.Online.country.countryCode.push(countryCode);
  }
  function getNameFlag(codigoPais) {
    return flagsCodesMap.get(codigoPais.toUpperCase()) || null;
  }
  if (series['countVisitToday']) {
    ventas['Online'].totalSesiones = series['countVisitToday'][0]['values'];
  }
  if (series['countVisitYesterday']) {
    ventas['Online'].sesionesAyer = series['countVisitYesterday'][0]['values'];
  }

  //Fin Venta Online

  //Venta Fisica
  if (series['apdexPresencial']) {
    ventas.Presencial.apdex = series['apdexPresencial'][0].values;
  }

  //Todo lo procesado aqui es por que la 'ventasHoyPresencialData' es temporal y se tiene que cruzar con la otra serie de ''ventasHoyPresencialValidacion' ftoro: 15/01/2024
  if (series['ventasHoyPresencialData']) {
    const pagadosMap = new Map();
    const promocionesMap = new Map();
    const metodosPagoMap = new Map();
    const visitasPorPaisMap = new Map();

    ventas.Presencial.tickets.hoy = Array(24).fill(0);
    ventas.Presencial.dinero.hoy = Array(24).fill(0);
    //ventas.Presencial.payMethod.methodType = [];
    //ventas.Presencial.payMethod.count = [];

    if (series['ventasHoyPresencialValidacion']) {
      series['ventasHoyPresencialValidacion'].forEach(item => {
        if (item.status === "PAID") {
          pagadosMap.set(item.id_trx, true);
        }
      });
    }

    series['ventasHoyPresencialData'].forEach(item => {
      if (item.status === "PAID" || pagadosMap.get(item.id_trx)) {
        const fecha = new Date(item.timestamp);
        const hora = fecha.getHours();
        const totalCompra = parseFloat(item.total_compra || 0);
        let methodPay = item.methodPay;

        // Transformación del método de pago
        if (methodPay === 'DEB') {
          methodPay = 'DEBITO';
        } else if (methodPay === 'Efectivo') {
          methodPay = 'EFECTIVO';
        } else if (methodPay !== 'EFECTIVO' && methodPay !== 'DEBITO') {
          methodPay = 'CREDITO';
        }

        // Sumar los tickets y el total de compra a la hora correspondiente
        ventas.Presencial.tickets.hoy[hora] += parseInt(item.Tickets || 0);
        ventas.Presencial.dinero.hoy[hora] += totalCompra;

        // Agregar al mapa de promociones y método de pago
        promocionesMap.set(totalCompra, (promocionesMap.get(totalCompra) || 0) + 1);
        metodosPagoMap.set(methodPay, (metodosPagoMap.get(methodPay) || 0) + 1);

        const codigoPais = item.nation;
        visitasPorPaisMap.set(codigoPais, (visitasPorPaisMap.get(codigoPais) || 0) + 1);
      }
    });

    // Convertir el mapa de promociones en arreglos para las promociones
    promocionesMap.forEach((repeticiones, total_compra) => {
      ventas.Presencial.promociones.repeticiones.push(repeticiones);
      ventas.Presencial.promociones.total_compra.push(total_compra);
    });

    // Convertir el mapa de métodos de pago en arreglos para los métodos de pago
    metodosPagoMap.forEach((count, methodType) => {
      ventas.Presencial.payMethod.methodType.push(methodType);
      ventas.Presencial.payMethod.count.push(count);
    });

    visitasPorPaisMap.forEach((numeroVisitas, codigoPais) => {
      var nombrePais = getNameFlag(codigoPais);
      ventas.Presencial.country.name.push(nombrePais);
      ventas.Presencial.country.numberVisits.push(numeroVisitas);
      ventas.Presencial.country.countryCode.push(codigoPais);
    });

  }

  if (series['ventasAyerPresencialData']) {
    const pagadosMap = new Map();

    ventas.Presencial.tickets.ayer = Array(24).fill(0);
    ventas.Presencial.dinero.ayer = Array(24).fill(0);


    if (series['ventasAyerPresencialValidacion']) {
      series['ventasAyerPresencialValidacion'].forEach(item => {
        if (item.status === "PAID") {
          pagadosMap.set(item.id_trx, true);
        }
      });
    }

    series['ventasAyerPresencialData'].forEach(item => {
      // Considerar el elemento si su estado es "PAID" o si está marcado como "PAID" en el pagadosMap
      if (item.status === "PAID" || pagadosMap.get(item.id_trx)) {
        const fecha = new Date(item.timestamp);
        const hora = fecha.getHours();


        ventas.Presencial.tickets.ayer[hora] += parseInt(item.Tickets || 0);
        ventas.Presencial.dinero.ayer[hora] += parseFloat(item.total_compra || 0);
      }
    });
  }

  if (series['ventaMesPresencialData'] && series['ventaMesPresencialValidacion']) {
    const pagadosMap = new Map();

    series['ventaMesPresencialValidacion'].forEach(item => {
      if (item.status === "PAID") {
        pagadosMap.set(item.id_trx, true);
      }
    });

    series['ventaMesPresencialData'].forEach(item => {
      if (item.status === "PAID" || pagadosMap.get(item.id_trx)) {
        ventas.Presencial.TotalTicketsMes += parseInt(item.Tickets || 0);
        ventas.Presencial.TotalMes += parseFloat(item.total_compra || 0);
      }
    });

  }





  return ventas

}
